.customPhoneInput {
  border-radius: 10px;
  border: 1px solid #42efeb;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);

  .PhoneInputCountrySelect {}

  .PhoneInputCountryIcon {
    height: 30px !important;
    width: 30px !important;
    margin: 0.375rem 0.25rem 0.375rem 0.75rem;
    height: 100%;

    svg.PhoneInputCountryIconImg {
      color: #42efeb;
    }
  }

  .PhoneInputCountrySelectArrow {
    display: none;
  }

  .PhoneInputInput {
    background: transparent;
    border: none;
    outline: none;
    color: #42efeb;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.375rem 0.75rem;
    height: 100%;

    &::placeholder {
      color: #42efeb;
    }
  }
}

.login-page .right-section .otp-form .form-control {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border: 1px solid #42efeb;
}

.customOtpInput {
  input {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 1px solid #FCB815;
    height: 38px !important;
    width: 36px !important;
    margin: 0 8px !important;
    outline: none;
    color: #42efeb;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

// === overlayLoader ===
.overlayLoader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #0000005b;
  z-index: 9999;

  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    .overlay__content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      span {
        height: 70px;
        width: 70px;
      }
    }
  }
}

.profile-page {
  .uploader {
    padding: 0;
    overflow: hidden;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

// ==========
.flipParent {
  perspective: 600px;

  .card {
    position: relative;
    width: 100%;
    cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
    background: transparent;
    border-width: 0;

    .login-box {
      position: absolute;
      width: 100%;
      backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      transform-style: preserve-3d;
    }

    .otp {
      transform: rotateY(180deg);
    }

    .mobile {}
  }

  .card.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }
}

@media (max-width:576px) {
  .customOtpInput input {
    width: 30px !important;
    height: 30px !important;
  }
}