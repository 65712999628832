@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "variable";

body {
    font-family: Poppins;
    background: url(../images/Background.png), linear-gradient(180deg, #46058F -18.33%, #2C0258 118.33%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    // background-size: cover;
    // width: 100%;
    // position: relative;
    background-size: 100%;
    min-height: 100vh;
}
.logo {
    height: 58px;
}
.w-10 {
    width: 14%;
}

.fontwhite60 {
    color: $white;
    font-size: 60px;
    font-weight: 600;
}
.fontwhite40 {
    color: $white;
    font-size: 40px;
    font-weight: 600;
}
.fontwhite30 {
    color: $white;
    font-size: 30px;
    font-weight: 500;
}

.fontwhite30-bold {
    color: $white;
    font-size: 30px;
    font-weight: 700;
}

.fontwhite24 {
    color: $white;
    font-size: 24px;
    font-weight: 700;
}

.fontwhite24-light {
    color: $white;
    font-size: 24px;
    font-weight: 500;
}

.fontwhite20 {
    color: $white;
    font-size: 20px;
    font-weight: 700;
}

.fontwhite20-light {
    color: $white;
    font-size: 20px;
    font-weight: 400;
}

.fontwhite16 {
    color: $white;
    font-size: 16px;
    font-weight: 400;
}

.fontwhite14 {
    color: $white;
    font-size: 14px;
    font-weight: 400;
}

.fontwhite12 {
    color: $white;
    font-size: 12px;
    font-weight: 400;
}

.fontskyblue24 {
    color: $skyblue;
    font-size: 24px;
    font-weight: 700;
}

.fontskyblue30 {
    color: $skyblue;
    font-size: 30px;
    font-weight: 600;
}

.font50skyblue {
    color: $skyblue;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
}

.font30wight {
    color: $white;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
}

.next-btn {
    border-radius: 10px;
    background: $skyblue;
    box-shadow: 0px 10px 40px 0px rgba(66, 239, 235, 0.40);
    color: #701CCE;
    text-align: center;
    font-size: 20px;
    font-weight: 500;

    &:hover {
        background-color: rgba(66, 239, 235, 0.9);
        color: #701CCE;
    }
    &.active,
    &:active {
        background-color: rgba(66, 239, 235, 0.9) !important;
        color: #701CCE !important;
    }
    &:disabled {
        background-color: rgba(66, 239, 235, 0.9) !important;
        color: #701CCE !important;
    }
}

.orange-btn {
    border-radius: 6px;
    background: $orange;
    color: $white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border: 0;
    padding: 7px 15px;

    &:hover {
        background-color: rgba(240, 121, 0, 0.9);
        color: $white;
    }
}

.pink-btn {
    border-radius: 6px;
    background: $pink;
    color: $white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border: 0;
    padding: 7px 15px;

    &:hover {
        background-color: rgba(183, 19, 203, 0.9);
        color: $white;
    }
}

.custom-navbar {
    background: rgba(0, 0, 0, 0.30);

    .nav-link {
        color: $white;
        margin-left: 20px;
        &:hover, &.active {
            color: $skyblue;
        }
    }

    .navbar-toggler {
        background-color: $skyblue;
    }

    .vertical-line {
        display: inline-block;
        height: 50px;
        width: 2px;
        background: white;
        margin: 0 20px;
    }
}

.custom-breadcrumb {
    .breadcrumb {
        .breadcrumb-item+.breadcrumb-item::before {
            color: $white;
        }

        .breadcrumb-item {
            color: $white;

            a {
                text-decoration: none;
                color: $skyblue
            }
        }

        &.active {
            color: $skyblue
        }
    }
}


// ==== login-page ====
.login-page {
    padding: 20px;
    .login-logo {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .vertical-line {
        height: 64px;
        width: 2px;
        background: white;
        margin: 0 21px
    }

    .left-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            color: #FCB815;
            font-size: 40px;
            font-weight: 500;
        }

        span {
            color: #FCB815;
            font-size: 40px;
            font-weight: 300;
        }
    }

    .right-section {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
margin-top: 100px;
        .login-box {
            border-radius: 20px;
            background: rgba(0, 0, 0, 0.30);
            width: 50%;
            padding: 25px;
        }

        .login-email {
            color: $white;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.28px;
            position: relative;
            align-items: center;
            justify-content: center;
            display: flex;
        }

        .login-email:before {
            content: "";
            display: block;
            width: 45%;
            height: 1px;
            background: #fff;
            left: 0px;
            top: 50%;
            position: absolute;
        }

        .login-email:after {
            content: "";
            display: block;
            width: 45%;
            height: 1px;
            background: #fff;
            right: 0px;
            top: 50%;
            position: absolute;
        }

        .login-form {
            .form-control {
                border-radius: 10px;
                border: 1px solid #42EFEB;
                height: 50px;
                background: rgba(0, 0, 0, 0.30);
                color: #42EFEB;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &::placeholder {
                    color: #42EFEB;
                }
            }

            .input-group {
                .input-group-text {
                    background-color: rgba(0, 0, 0, 0.30) !important;
                    border: 1px solid #42EFEB;
                }
            }
        }

        .otp-form {
            .form-control {
                background: rgba(0, 0, 0, 0.30);
                border-radius: 10px;
                border: 1px solid #42EFEB;

                &::placeholder {
                    color: #42EFEB;
                }
            }
        }

        .otp-span {
            color: #FCB815 !important;
            font-size: 14px;
            font-weight: 500;
        }
    }

}

// ==== login-page ====

// ==== persona-page ====
.persona-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    .persona-box {
        // width: 50%;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.30);
    }

    .form-width {
        width: 50%;
    }

    label {
        font-size: 16px;
        color: $white;
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        border-radius: 30px;
        border: 2px solid #DEBCFB;
        background: var(--bg, radial-gradient(141.42% 141.42% at 0% 0%, #711BBC 0%, #4E12A5 100%));
        box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);
        margin-bottom: 20px;
        margin-right: 20px;
    }

    label input {
        position: absolute;
        left: -9999px;
    }

    label input:checked+span {
        border-radius: 50px;
        background: radial-gradient(141.42% 141.42% at 0% 0%, #D439E7 0%, #B713CB 100%);
        box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);
    }

    label span {
        display: flex;
        align-items: center;
        padding: 0.375em 0.75em 0.375em 0.375em;
        border-radius: 50px;
        white-space: nowrap;
    }
}

// ==== persona-page ====

// ==== profile-page ====
.profile-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    .profile-box {
        width:75%;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.3);
    }

    .uploader {
        border: 2px solid $skyblue;
        border-radius: 50%;
        padding: 15px;
        height: 80px;
        width: 80px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .uploader input {
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border-radius: 10px;
        opacity: 0;
    }
    label {
        display: block;
        padding: .2em;
    }
    .size-2 {
        color: $skyblue;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }


    /* MAGIC HAPPENS HERE */

    $radioColor: $skyblue;

    input[type="radio"] {
        visibility: hidden;
        position: relative;
        margin: 0 .2em 0 0;
        font-size: 30px;
        height: 1em;
        width: 1em;

        &:before,
        &:after {
            content: '';
            visibility: visible;
            display: block;
            position: absolute;
            touch-action: manipulation;
            cursor: pointer;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 1em;
            height: 1em;
            background-color: transparent;
            border-radius: 50%;

        }

        &:before {
            /* border */
            border: .075em solid $skyblue;
            transition: border-color .3s ease-in-out;
        }

        &:after {
            /* centered dot */
            transform: scale(0);
            background-color: $radioColor;
            transition: transform .3s cubic-bezier(0.4, -0.8, 0.5, 1);
        }

        &:checked {
            &:before {
                border-color: $radioColor;
            }

            &:after {
                transform: scale(0.6);
                transition: transform .3s cubic-bezier(0.5, 0, 0.6, 1.8);
            }
        }
    }
}

.homepage {
    .homepage-card {
        .card {
            border-radius: 16px;
            border: 2px solid #DEBCFB;
            background: var(--bg, radial-gradient(141.42% 141.42% at 0% 0%, #711BBC 0%, #4E12A5 100%));
            box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);
           
            .cardImg {
                height: 201px;
            }
            .card-img {
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
              min-width: 100px;
               height: 100%;
            }

            .card-title {
                color: $white;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
            }

            .progress-bar {
                border-radius: 20px;
                background: #B713CB;
            }

            .progress {
                height: 8px;
            }
        }
    }
}

.info-page {
    .info-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.leaderboard-page {
    .leaderboard-box {
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.30);

        .left-section {
            border-right: 1px solid #624582;
            padding: 150px 30px 30px;

            .playerimg {
                width: 100px;
                height: 100px;
                border-radius: 50px;
                border: 3px solid $skyblue;
            }

            .custom-first {
                margin-top: -150px;
            }
        }

        .right-section {
            padding: 30px;

            .resultBG {
                border-radius: 37px;
                border: 2px solid #DEBCFB;
                background: var(--bg, radial-gradient(141.42% 141.42% at 0% 0%, #711BBC 0%, #4E12A5 100%));
                box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);
                padding: 10px;

                .winnerPic {
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                    img{
                        width: 50px;
                        height: 50px;
                        border-radius: 50px;
                    }
                }

                .fontskyblue18 {
                    color: $skyblue;
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }
}

.backLink {
    color: $skyblue;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &:hover {
        color: $skyblue;
        text-decoration: underline;
    }
}

.heightFull {
    min-height: calc(100vh - 100px);
}

.sponsoredImg {
    max-width: 100px;
    max-height: 40px;
}

.horScrollHide {
    overflow-x: hidden;
}

.question {
    position: relative;
    margin-bottom: 10px;

    img {
        border-radius: 16px;
        max-height: 400px;
        width: 100%;
    }

    .questionText {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px;
        border-radius: 0 0 16px 16px;
        background: rgba(0, 0, 0, 0.40);
        color: $white;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.questionNumber {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.20);
    color: $white;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 5px 10px;
    margin-left: 35px;
}

.questionTimer {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.10);
    color: $white;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 5px 10px;
    margin-right: 35px;
}

.question-circle{
    width: 120px;
    height: 120px;
    border-radius: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

background: var(--color-app-bg, linear-gradient(180deg, #46058F -18.33%, #2C0258 118.33%));
box-shadow: 0px 0px 20px 0px #FFF;
}
.content-question{
    .ans1Line{
        .ans1{
            min-height: 120px;
            .inner{
                .bg{
                    padding: 12px 58px;
                }
            }
        }
    }
}
/* Default styles for the div */
.my-div {
    font-size: 20px;
  }
  
  /* Styles for small div */
  .small-div {
    font-size: 2px;
  }
  
  /* Styles for large div */
  .large-div {
    /* Add styles for large div here */
  }
  
.ans1Line {
    .ans1 {
        clip-path: polygon(90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%, 10% 0);
        height: 64px;
        background: linear-gradient(90deg, #DFBCFC 34.35%, #871FE9 100%);
        padding: 4px 6px;

        .inner {
            clip-path: polygon(90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%, 10% 0);
            background: #46058F;
            height: 100%;
            padding: 4px 6px;

            .bg {
                clip-path: polygon(90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%, 10% 0);
                background: linear-gradient(90deg, #6E1BBA 36.21%, #5815AC 171.04%);
                height: 100%;
                color: $white;
                text-align: center;
                font-family: Poppins;
                font-size: calc(0.625rem + ((1vw - 2px) * 0.375));
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;

                &.ansRight {
                    background: linear-gradient(90deg, #58B000 0%, #BDBB7F 65.81%);
                }

                &.ansWrong {
                    background: linear-gradient(90deg, #CB0303 0%, #AA5563 65.81%);
                }
            }
        }
    }

}

.lineLeft {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: calc(50% - 3px);
        background-color: #DCB7FC;
        height: 6px;
        left: calc(-100% - 10px);
        width: calc(100% + 10px);
        margin-left: 5px;
    }
}

.lineRight {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 3px);
        background-color: #8821EA;
        height: 6px;
        right: calc(-100% - 10px);
        width: calc(100% + 10px);
        margin-right: 5px;
    }
}

.list-page {
    .list-box {
        .listbox-inner {
            border-radius: 16px;
            border: 2px solid #DEBCFB;
            background: var(--bg, radial-gradient(141.42% 141.42% at 0% 0%, #711BBC 0%, #4E12A5 100%));
            box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);
        }
    }
    .upload-img{
        border-radius: 16px;
        border: 2px solid #DEBCFB;
        background: var(--bg, radial-gradient(141.42% 141.42% at 0% 0%, #711BBC 0%, #4E12A5 100%));
        box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);
    }
}

.congrats-page {
    min-height: 100vh;

    .congrats-circle {
        width: 170px;
        height: 170px;
        border-radius: 100px;
        border: 1px solid $white;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .congrats-box {
        padding: 40px 30px;
        border-radius: 16px;
        border: 2px solid #DEBCFB;
        background: var(--bg, radial-gradient(141.42% 141.42% at 0% 0%, #711BBC 0%, #4E12A5 100%));
        box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);

        .total-circle {
            width: 35px;
            height: 35px;
            border-radius: 50px;
            background-color: #B713CB;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .custom-process {
            .progress {
                border-radius: 20px;
                height: 12px;
                font-size: 8px;
            }

            .progress-bar {
                background-color: #11C36A;
            }
        }

        .custom-wrong {
            .progress {
                border-radius: 20px;
                height: 12px;
                font-size: 8px;
            }

            .progress-bar {
                background-color: #DA6723;
                ;
            }
        }
    }
}

.myprofile-page {
    .myprofile-box {
        padding: 40px 20px;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.30);
        margin-bottom: 20px;
    }

    .content-box {
        border-radius: 16px;
        border: 2px solid #DEBCFB;
        background: var(--bg, radial-gradient(141.42% 141.42% at 0% 0%, #711BBC 0%, #4E12A5 100%));
        box-shadow: 0px 4px 10px 0px rgba(33, 0, 74, 0.30);

        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 20px 30px;
            border-bottom: 1px solid  rgba(255, 255, 255, 0.50);
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.question-box {
    padding: 40px 20px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.30);
}

.swal2-container{
    .swal2-modal{
        background-color: #701CCE;
        h2{
            color: #fff;
        }
        .swal2-html-container{
            color: #fff;
        }
        .swal2-warning{
            color:#fff;
            border-color: #fff;
        } 
        .swal2-actions{
            display: flex;
            .swal2-confirm{
                background-color: #42EFEB !important;
                color: #701CCE !important;
                border-radius: 30px;
                min-width: 150px;
             
            }
            .swal2-cancel{
                background-color: #CB0303  !important;
                border-radius: 30px;
                min-width: 150px;
            }
        }
        
    }
}

.header1 {
    .logo1, .logo2 {
        height: 50px;
    }
}

@media (max-width:500px) {
    .header1 {
        .logo1, .logo2 {
            height: 32px;
        }
        .vertical-line {
            height: 32px;
            margin: 0 7px;
        }
    }
}


// ==== Responsive-css ====
@media (max-width:1500px) {
    .login-page .right-section .login-box {
        width: 80%
    }

}
@media (max-width:1200px) {
    .login-page .right-section .login-box {
        width: 80%
    }

}

@media (max-width:992px) {
    .login-page .right-section{
        margin-top: 0;
    }
   
    .login-page .left-section {
        display: none;
    }

    .login-page .login-logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile-page .profile-box {
        width: 100%;
    }

    .leaderboard-page .leaderboard-box .left-section {
        border: 0;
    }
}

@media (max-width:768px) {
    // .login-page .right-section .login-box {
    //     width: 90%
    // }
    .heightFull{
        // min-height: inherit;
    }
    .login-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 70px !important;
    }
.persona-page{
    min-height: inherit;
}
    body{
        min-height: inherit;
    }
    .persona-page .persona-box {
        width: 80%;
    }

    .persona-page .form-width {
        width: 80%;
    }

    .customOtpInput input {
        margin: 0 6px !important;
    }
    .custom-navbar .nav-link {
        text-align: center;
    }
}

@media (max-width:576px) {
    .login-page .right-section .login-box{
        padding: 15px;
    }
    .login-page{
        padding: 0px;
    }
  
    .mobview{
        display: flex;
        padding: 10px;
        .card-body{
            padding: 0 10px;
        }
    }
  
    .homepage .homepage-card .card .cardImg{
        width: 100px;
        height: 100px;
    }
    .homepage .homepage-card .card .card-img{
        border-radius: 10px;
    }
    .login-page .right-section .login-box {
        width: 100%
    }

    .persona-page .form-width {
        width: 90%;
    }

    .persona-page .persona-box {
        width: 100%;
    }

    .leaderboard-page .leaderboard-box .left-section {
        padding: 80px 20px 0;
    }

    .leaderboard-page .leaderboard-box .left-section .custom-first {
        margin-top: -80px;
    }

    .taj {
        width: 30px;
    }

    .leaderboard-page .leaderboard-box .left-section .playerimg {
        width: 70px;
        height: 70px
    }
    .question {
        margin-bottom: 30px;
    }
    .ans1Line .ans1 .inner .bg {
        font-size: calc(0.625rem + ((1vw - 2px) * 0.375));
    }
    .myprofile-page .content-box .content {
        padding: 10px 15px;
    }
}

@media (min-width: 320px) {
    .font50skyblue {
        font-size: calc(1.5rem + ((1vw - 3.2px) * 1.625));
    }
    .fontwhite40 {
        font-size: calc(2.125rem + ((1vw - 3.2px) * 0.375));
      }
    .font30wight {
        font-size: calc(0.625rem + ((1vw - 2px) * 1.25));
    }
    .question .questionText {
        font-size: calc(0.875rem + ((1vw - 3.2px) * 0.25));
    }
    .fontwhite60 {
        font-size: calc(2.75rem + ((1vw - 3.2px) * 1));
      }
      .fontwhite30 , .fontwhite30-bold , .fontskyblue30{
        font-size: calc(1.25rem + ((1vw - 3.2px) * 0.625));
      }
      .fontwhite24 , .fontwhite24-light , .fontskyblue24{
        font-size: calc(1.25rem + ((1vw - 3.2px) * 0.25));
      }
      .fontwhite20 , .fontwhite20-light{
        font-size: calc(1.125rem + ((1vw - 3.2px) * 0.125));
      }
      .fontwhite16 {
        font-size: calc(0.875rem + ((1vw - 3.2px) * 0.125));
      }
}
@media (min-width: 768px) {
    .justify-content-md-center {
      justify-content: center !important;
    }
  }

@media (max-width: 900px) and (orientation: landscape) {

#question-id {
	// width: 100vw !important;
    //   height: 100vh !important;
    width: 100%;
	height: 100%;
    background: url(../images/Background.png), linear-gradient(180deg, #46058F -18.33%, #2C0258 118.33%);
    background-repeat: no-repeat;
    background-attachment: fixed;

    // ========
    .question-box {
        padding: 16px;
        ._line {
            margin-top: 8px !important;
        }
    }
    
    .question {
        margin-bottom: -3px  ;
        img {
            max-height: 120px;
            width: unset;
        }
    }
    .ans1Line .ans1 {
        height: 40px;
        padding: 2px 2px;
    }
    .ans1Line .ans1 .inner {
        padding: 3px 4px;
    }
    .ans1Line .ans1 .inner .bg {
        font-size: 10px;
    }
    .fontwhite14 {
        font-size: 10px;
    }
    .sponsoredImg {
        max-width: 65px;
        max-height: unset;
        height: auto;
    }
    .questionNumber, .questionTimer {
        font-size: 10px;
        padding: 3px 6px;
        line-height: 16px;
    }

    .content-question .ans1Line .ans1 {
        min-height: 60px;
      }
      .question-circle {
        width: 35px;
        height: 35px;
        .fontwhite24-light {
            font-size: 11px;
        }
      }


}

/* prefix for Chrome & Safari */
#question-id:-webkit-full-screen {
    // width: 100vw !important;
    //   height: 100vh !important;
	width: 100%;
	height: 100%;
    background: url(../images/Background.png), linear-gradient(180deg, #46058F -18.33%, #2C0258 118.33%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    // ========
    .question-box {
        padding: 16px;
        ._line {
            margin-top: 8px !important;
        }
    }
    
    .question {
        // margin-bottom: 6px  ;
        img {
            max-height: 120px;
            width: unset;
        }
    }
    .ans1Line .ans1 {
        height: 40px;
        padding: 2px 2px;
    }
    .ans1Line .ans1 .inner {
        padding: 3px 4px;
    }
    .ans1Line .ans1 .inner .bg {
        font-size: 10px;
    }
    .fontwhite14 {
        font-size: 10px;
    }
    .sponsoredImg {
        max-width: 65px;
        max-height: unset;
        height: auto;
    }
    .questionNumber, .questionTimer {
        font-size: 10px;
        padding: 3px 6px;
    }

    .content-question .ans1Line .ans1 {
        min-height: 60px;
      }
      .question-circle {
        width: 35px;
        height: 35px;
        .fontwhite24-light {
            font-size: 11px;
        }
      }
   

}
}

.swal2-container {
    z-index: 999999 !important;
}

.loginBG {
    background-image: url(../images/bgDark.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.fontYellow24 {
    color: #FCB815;
    font-size: 24px;
    font-weight: 700;
}
.customPhoneInputYellow {
    border-radius: 10px;
    border: 1px solid #FCB815;
    height: 50px;
    background: rgba(0, 0, 0, 0.3);
    .PhoneInputCountrySelect {}

    .PhoneInputCountryIcon {
      height: 30px !important;
      width: 30px !important;
      margin: 0.375rem 0.25rem 0.375rem 0.75rem;
      height: 100%;
  
      svg.PhoneInputCountryIconImg {
        color: #FCB815;
      }
      .PhoneInputCountrySelectArrow {
        display: none;
      }
    
    }
    .PhoneInputInput {
      background: transparent;
      border: none;
      outline: none;
      color: #FCB815;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0.375rem 0.75rem;
      height: 100%;
  
      &::placeholder {
        color: #FCB815;
        background-color: transparent;
      }
    }
}
.yellow-btn {
    border-radius: 10px;
    background: #FCB815;
    box-shadow: 0px 10px 40px 0px rgba(252, 184, 21, 0.40);
    color: #7D1746;
    text-align: center;
    font-size: 20px;
    font-weight: 500;

    &:hover {
        background-color: rgba(252, 184, 21, .8);
        color: #7D1746;
    }
    &.active,
    &:active {
        background-color: rgba(252, 184, 21, 0.9) !important;
        color: #7D1746 !important;
    }
}
// .image-container {
//     width: 100%;
//     height: 100vh; // or set a specific height
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//   }
  
//   .image-container img {
//     width: 100%; // Make image responsive
//     height: auto; // Maintain aspect ratio
//   }
//   @media only screen and (orientation: landscape) {
//     .image-container {
//       max-height: 30vh; // Adjust height in landscape mode
//     }
//   }
    /* Landscape Mode */
@media screen and (orientation: landscape) {
    .img-landscape {
      max-width: 60% !important;
      height: auto !important;
    }
  }
  .mt5px {
    margin: 5px 0 !important;
  }